<template>
  <div class="overlay" :active="opened" @click="opened = false">
    <div class="modal" @click.stop>
      <div class="modal-header">
        <div class="modal-title" v-if="forgotPassword">{{ t('Reset Password', lang) }}</div>
        <div class="modal-title" v-else-if="isSignUp">{{ t('Sign Up', lang) }}</div>
        <div class="modal-title" v-else>{{ t('Sign In', lang) }}</div>
        <a @keypress.space="opened = false" @click="opened = false">
          <span class="material-symbols-outlined">close</span>
        </a>
      </div>
      <div class="modal-content">
        <form v-if="forgotPassword" class="form" @submit.prevent="resetPassword">
          <div class="message" v-if="verification?.is_legacy">
            <h5>{{ t('Welcome back!', lang) }} 👋</h5>
            {{ t('It seems to be the first time you connect to the new Bad Cards website. Get a fresh password to login!', lang) }}
          </div>
          <div class="field">
            <div class="field-label">{{ t('Email', lang) }}</div>
            <input type="email" class="input" maxlength="255" v-model="authForm.email" :disabled="loading || !!verification" required>
          </div>
          <div class="actions">
            <button type="submit" class="btn" :disabled="!authForm.email || loading">{{ t('Reset my password', lang) }}</button>
            <a @click="forgotPassword = false">{{ t('I know my password!', lang) }}</a>
          </div>
        </form>
        <form v-else class="form" @submit.prevent="signIn('email')">
          <div class="field" v-if="isSignUp">
            <div class="field-label">{{ t('Username', lang) }}</div>
            <input type="text" class="input" maxlength="20" v-model="authForm.name" :disabled="loading" required>
          </div>
          <div class="field">
            <div class="field-label">{{ t('Email', lang) }}</div>
            <input type="email" autocomplete="username" class="input" maxlength="255" v-model="authForm.email" :disabled="loading || !!verification" required>
          </div>
          <div class="field">
            <div class="field-label">{{ t('Password', lang) }}</div>
            <input type="password" autocomplete="current-password" class="input" minlength="8" maxlength="20" v-model="authForm.password" :disabled="loading" required>
            <div class="field-note" v-if="!isSignUp"><a @click="verification = null; forgotPassword = true">{{ t('Forgot your password?', lang) }}</a></div>
          </div>
          <div class="actions">
            <button v-if="isSignUp" type="submit" class="btn" :disabled="!authForm.name || !authForm.email || authForm.password.length < 8 || loading">{{ t('Sign Up', lang) }}</button>
            <button v-else-if="verification" type="submit" class="btn" :disabled="!authForm.email || authForm.password.length < 8 || loading">{{ t('Sign In', lang) }}</button>
            <button v-else type="submit" class="btn" :disabled="!authForm.email || loading">{{ t('Next', lang) }}</button>
            <a v-if="isSignUp" @click="isSignUp = false">{{ t('I already have an account.', lang) }}</a>
            <a v-else @click="isSignUp = true">{{ t('Don\'t have an account?', lang) }}</a>
          </div>
          <div class="separator">OR</div>
          <div class="buttons">
            <button
              type="button"
              class="btn google"
              :disabled="loading"
              @click="signIn('google')"
            >
              Connect with Google
            </button>
            <button
              type="button"
              class="btn facebook"
              :disabled="loading"
              @click="signIn('facebook')"
            >
              Connect with Facebook
            </button>
            <button
              type="button"
              class="btn twitch"
              :disabled="loading"
              @click="signIn('twitch')"
            >
              <img src="/assets/twitch.svg" alt="Twitch" class="icon">
              Connect with Twitch
            </button>
            <button
              type="button"
              class="btn discord"
              :disabled="loading"
              @click="signIn('discord')"
            >
              Connect with
              <img src="/assets/discord.svg" alt="Discord" class="icon icon-logo">
            </button>
            <button
              type="button"
              class="btn patreon"
              :disabled="loading"
              @click="signIn('patreon')"
            >
              <img src="/assets/patreon.svg" alt="Patreon" class="icon icon-logo">
              Connect with Patreon
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createUserWithEmailAndPassword, GoogleAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup,
} from 'firebase/auth';
import { mapActions, mapState } from 'vuex';
import { showError } from '../utils/error-handling';
import { auth, sendEvent } from '../services/firebase';
import { APIClient } from '../services/api/api';

export default {
  name: 'AuthModal',
  computed: {
    ...mapState(['client', 'lang']),
  },
  data() {
    return {
      opened: false,
      isSignUp: false,
      forgotPassword: false,
      loading: false,
      verification: null,
      authForm: {
        name: '',
        email: '',
        password: '',
      },
    };
  },
  methods: {
    ...mapActions(['showMessage']),
    open(e) {
      this.isSignUp = e.detail?.form === 'sign-up';
      this.authForm = {
        name: '',
        email: '',
        password: '',
      };
      this.forgotPassword = false;
      this.verification = null;
      this.opened = true;
    },
    close() {
      this.opened = false;
    },
    async resetPassword() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        await sendPasswordResetEmail(auth, this.authForm.email);
        this.showMessage({
          type: 'success',
          message: 'The instructions to reset your password were sent to your email address.',
        });
        this.forgotPassword = false;
        sendEvent('password-reset');
      } catch (error) {
        showError(error);
      }
      this.loading = false;
    },
    async signIn(method) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        switch (method) {
          case 'google': {
            const provider = new GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            sendEvent('sign-in', { method });
            await signInWithPopup(auth, provider);
            break;
          }
          case 'facebook':
          case 'twitch':
          case 'patreon':
          case 'discord': {
            const match = window.location.pathname.match(/\/g\/([a-z0-9_-]+)/i);
            let qs;
            if (match) {
              qs = { gameId: match[1] };
            }
            sendEvent('sign-in', { method });
            const { url } = await this.client.get(`/${method}/connect`, qs);
            window.location.href = url;
            return;
          }
          case 'email': {
            if (this.isSignUp) {
              const credentials = await createUserWithEmailAndPassword(auth, this.authForm.email, this.authForm.password);
              const client = new APIClient(credentials.user);
              await client.get('/account');
              const user = await client.post('/account', { name: this.authForm.name });
              this.$store.commit('setUser', user);
              sendEvent('sign-up', { method });
            // } else if (!this.verification) {
            //   this.verification = await this.client.post('/auth/email', { email: this.authForm.email });
            //   if (!this.verification.exists) {
            //     this.verification = null;
            //     throw new Error('We did not find any account matching this email address.');
            //   }
            //   if (this.verification.is_legacy && this.verification.first_login) {
            //     this.forgotPassword = true;
            //   }
            //   this.loading = false;
            //   return;
            } else {
              await signInWithEmailAndPassword(auth, this.authForm.email, this.authForm.password);
              sendEvent('sign-in', { method });
            }
            break;
          }
          default:
            throw new Error('Unknown auth method');
        }
        this.close();
      } catch (error) {
        showError(error);
      }
      this.loading = false;
    },
  },
  mounted() {
    window.addEventListener('open-auth', this.open);
  },
  beforeDestroy() {
    window.removeEventListener('open-auth', this.open);
  },
};
</script>

<style scoped>
.modal {
  width: 400px;
  padding: 12px 32px 24px;
}

.input,
.btn {
  width: 100%;
}

.separator {
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0.5;
  margin: 16px 0;
}
.separator::before,
.separator::after {
  content: '';
  display: block;
  flex-grow: 1;
  height: 1px;
  background: #fff;
}

.buttons .btn {
  margin: 8px 0;
}

.form .actions {
  justify-content: center;
  gap: 12px;
}

.message {
  background: rgba(94, 81, 215, 0.48);
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  margin-bottom: 24px;
}
.message h5 {
  margin: 0 0 12px;
  font-size: 18px;
}
</style>
