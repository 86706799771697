<template>
  <div class="ramp-container"></div>
</template>

<script>
export default {
  name: 'RampManager',
  data() {
    return {
      rampLoaded: false,
    };
  },
  props: {
    pubId: {
      type: String,
      required: true,
    },
    websiteId: {
      type: String,
      required: true,
    },
  },
  watch: {
    $route() {
      this.refreshAd();
    },
  },
  methods: {
    refreshAd() {
      if (window.ramp) {
        window.ramp.que.push(() => {
          console.log('REFRESH AD', this.$route.path);
          window.ramp.spaNewPage(this.$route.path);
        });
      }
    },
    initRamp() {
      if (!this.pubId || !this.websiteId) {
        console.warn('[RAMP] Missing pubId or websiteId');
        return;
      }

      if (window.ramp) {
        this.refreshAd();
        return;
      }

      window.ramp = window.ramp || {};
      window.ramp.que = window.ramp.que || [];
      window.ramp.passiveMode = true;

      const script = document.createElement('script');
      script.src = `https://cdn.intergient.com/${this.pubId}/${this.websiteId}/ramp.js`;

      script.onload = () => {
        if (window.ramp) {
          window.ramp.que.push(() => {
            console.log('INIT AD', this.$route.path);
            window.ramp.spaNewPage(this.$route.path);
            this.rampLoaded = true;
          });
        }
      };

      document.body.appendChild(script);
    },
  },
  mounted() {
    setTimeout(this.initRamp, 1000);
  },
};
</script>
