<template>
  <footer>
    <div class="footer-actions">
      <button v-if="user.name" class="btn small link feedback-btn" @click="openFeedback">
        <span class="material-symbols-outlined">feedback</span>
        {{ t('Feedback', lang) }}
      </button>
    </div>
    <div>
      <a class="footer-link" href="http://twitter.com/playbadcards/" target="_blank" rel="noopener noreferrer">X</a>
      <a class="footer-link" href="https://instagram.com/playbadcards?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">Instagram</a>
      <a class="footer-link" href="https://reddit.com/r/allbadcards" target="_blank" rel="noopener noreferrer">Reddit</a>
      <a class="footer-link" href="http://patreon.com/allbadcards/" target="_blank" rel="noopener noreferrer">Patreon</a>
    </div>
    <div style="margin-top: 24px;">
      <a href="/page/cards-against-humanity-online-vs-bad-cards" class="footer-link" target="_blank" rel="noopener noreferrer">{{ t('Cards Against Humanity Online vs. Bad Cards', lang) }}</a>
      <a href="/page/about" class="footer-link" target="_blank" rel="noopener noreferrer">{{ t('About', lang) }}</a>
      <a href="/page/terms-of-service" class="footer-link" target="_blank" rel="noopener noreferrer">{{ t('Terms of Service', lang) }}</a>
      <a class="footer-link" href="/page/privacy-policy" target="_blank" rel="noopener noreferrer">{{ t('Privacy Policy', lang) }}</a>
      <a class="footer-link" href="/page/cookie-policy" target="_blank" rel="noopener noreferrer">{{ t('Cookie Policy', lang) }}</a>
      <a class="footer-link" href="/page/acceptable-use-policy" target="_blank" rel="noopener noreferrer">{{ t('Acceptable Use', lang) }}</a>
      <a class="footer-link" href="/page/return-refund-policy" target="_blank" rel="noopener noreferrer">{{ t('Returns & Refunds', lang) }}</a>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PageFooter',
  computed: {
    ...mapState(['user', 'lang']),
  },
  methods: {
    openFeedback() {
      window.dispatchEvent(new CustomEvent('open-feedback'));
    },
  },
};
</script>

<style scoped>
footer {
  text-align: center;
  margin-top: 24px;
}

footer .footer-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

footer .footer-link {
  display: inline-block;
  color: #ccc;
  text-transform: uppercase;
  font-size: 14px;
  padding: 4px 12px;
  white-space: nowrap;
}

footer .footer-link:hover {
  text-decoration: underline;
}

footer .footer-link:not(:last-child) {
  border-right: 1px solid #ccc;
}

@media screen and (max-width: 540px) {
  footer .footer-link {
    font-size: 11px;
    padding: 4px 8px;
  }
}
</style>
